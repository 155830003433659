@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/poppins');

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

.cursive {
  font-family: 'Poppins',Verdana,Tahoma sans-serif;
  font-weight: 700;
}

.prose-img{
  margin-left: auto;
  margin-right: auto;
  border-radius: 5 px;
}

.home-name {
  font-size: 150 px;
}

h1{
  font-family: 'Josefin Sans', sans-serif;
  font-size: 200 px;
  text-transform: uppercase;
}

h2{
  font-family: 'Poppins',Verdana,Tahoma sans-serif;
  font-weight: 200;
  
}

p{
  font-family: 'Poppins',Verdana,Tahoma sans-serif;
  font-weight: 100;
  color: #014052;
  
}

body {
  background-color: #f2f2f2;
}

 

